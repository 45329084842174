/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

// TODO : fast scroll timeout/background fix

import React, { Component, useContext } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _ from "underscore";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { graphql } from "gatsby";
import { disableBodyScroll } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import { AWSContext } from "~context/AWSContext";
import { DocumentContext } from "~context/DocumentContext";
import Layout from "~components/Layout";
import PersonaHoverGrid from "~components/PersonaHoverGrid";
import RotatingButtonLink from "~components/RotatingButtonLink";
import SEO from "~components/SEO";
import SignUpForm from "~components/SignUpForm";
import { fancyWarning } from "~utils/helpers";

import SVGChain from "~components/svg/SVGChain";
import SVGFacebook from "~components/svg/SVGFacebook";
import SVGTwitter from "~components/svg/SVGTwitter";

class PersonaPageComponent extends Component {
  state = {
    copied: false,
    dateBackgroundOpacity: 0,
    dateSticky: 0,
    neutralBackgroundOpacity: 0,
    percentage: null
  };

  dateBackgroundRef = React.createRef();

  dateStickyRef = React.createRef();

  formRef = React.createRef();

  mounted = false;

  personaGridRef = React.createRef();

  neutralBackgroundRef = React.createRef();

  personaDate = null;

  resultFetchCount = 0;

  timeouts = [];

  componentDidMount() {
    const { appContext, frontmatter, personas, shareUrl } = this.props;

    appContext.setActivePath(`/personas`);
    appContext.setShareUrl(shareUrl);

    personas.forEach(propsPersona => {
      if (this.personaDate) {
        return;
      }

      if (frontmatter.dinnerDate === propsPersona.title) {
        this.personaDate = propsPersona;
      }
    });

    if (document) {
      this.throttledHandleScroll = _.throttle(this.handleScroll);
      document.addEventListener(`scroll`, this.throttledHandleScroll, false);
    }

    //

    this.mounted = true;

    setTimeout(() => {
      this.getResults();
    });
  }

  componentWillUnmount() {
    this.mounted = false;

    this.clearTimeouts();

    if (document) {
      document.removeEventListener(`scroll`, this.throttledHandleScroll, false);
    }
  }

  //

  clearTimeouts = () => {
    if (this.timeouts.length > 0) {
      this.timeouts.forEach(timeout => {
        clearTimeout(timeout);
      });
    }
  };

  handleScroll = e => {
    const { documentContext } = this.props;
    const { scrollTop } = e.target.scrollingElement;

    //

    if (this.neutralBackgroundRef && this.neutralBackgroundRef.current) {
      const lastNeutralBackgroundOpacity = this.state.neutralBackgroundOpacity;

      const neutralThreshold =
        this.neutralBackgroundRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        documentContext.windowHeight;

      if (scrollTop > neutralThreshold) {
        let neutralBackgroundOpacity = parseFloat(
          (scrollTop - neutralThreshold) / documentContext.windowHeight
        ).toFixed(2);

        if (neutralBackgroundOpacity > 1) {
          neutralBackgroundOpacity = 1;
        }

        if (neutralBackgroundOpacity !== lastNeutralBackgroundOpacity) {
          requestAnimationFrame(() => {
            this.setState({
              neutralBackgroundOpacity
            });
          });
        }
      }
    }

    //

    if (this.dateBackgroundRef && this.dateBackgroundRef.current) {
      const lastDateBackgroundOpacity = this.state.dateBackgroundOpacity;

      const dateThreshold =
        this.dateBackgroundRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        documentContext.windowHeight;

      if (scrollTop > dateThreshold) {
        let dateBackgroundOpacity = parseFloat(
          (scrollTop - dateThreshold) / documentContext.windowHeight
        ).toFixed(2);

        if (dateBackgroundOpacity > 1) {
          dateBackgroundOpacity = 1;
        }

        if (dateBackgroundOpacity !== lastDateBackgroundOpacity) {
          requestAnimationFrame(() => {
            this.setState({
              dateBackgroundOpacity
            });
          });
        }
      }
    }

    //

    if (documentContext.device !== `desktop`) {
      return;
    }

    //

    if (this.dateStickyRef && this.dateStickyRef.current) {
      const dateStickyThreshold =
        this.dateStickyRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        documentContext.windowHeight / 2;

      if (scrollTop > dateStickyThreshold) {
        this.setState({
          dateSticky: true
        });
      } else {
        this.setState({
          dateSticky: false
        });
      }
    }
  };

  //

  getResults = () => {
    if (!this.mounted) {
      return;
    }

    const { awsContext, frontmatter } = this.props;
    const { data } = awsContext;

    if (data === null) {
      if (this.resultFetchCount > 10) {
        fancyWarning(`Failed to fetch AWS data`);

        return;
      }

      setTimeout(() => {
        this.resultFetchCount += 1;
        this.getResults();
      }, 500);

      return;
    }

    let totalResultCount = 0;
    let personaResultCount = 0;

    data.forEach(result => {
      totalResultCount += result.count;

      if (result.key === frontmatter.title) {
        personaResultCount = result.count;
      }
    });

    if (personaResultCount > 0 && totalResultCount > 0) {
      this.setState({
        percentage: parseInt((personaResultCount / totalResultCount) * 100)
      });
    }
  };

  share = () => {
    const { appContext, documentContext, frontmatter, shareUrl } = this.props;

    if (documentContext.device === `desktop` || !window.navigator.share) {
      disableBodyScroll();

      appContext.setShareMenuActive(true);

      return;
    }

    const title = `MYFOODIE.ID | ${frontmatter.title}`;

    window.navigator.share({
      title,
      url: shareUrl
    });
  };

  waypointEnter = ref => {
    if (!ref || !ref.current) {
      return;
    }

    ref.current.play();
  };

  waypointLeave = ref => {
    if (!ref || !ref.current) {
      return;
    }

    ref.current.pause();
  };

  //

  render() {
    const {
      documentContext,
      frontmatter,
      personas,
      siteUrl,
      shareUrl
    } = this.props;
    const {
      copied,
      dateBackgroundOpacity,
      neutralBackgroundOpacity,
      percentage
    } = this.state;

    const { device } = documentContext;
    const seoTitle = frontmatter.title.toLowerCase().replace(` `, `-`);

    let personaBackgroundColor = ``;
    let personaTextColor = ``;

    frontmatter.colorClasses.split(` `).forEach((colorClass, index) => {
      if (index === 0) {
        personaBackgroundColor = colorClass.trim().replace(`bg-`, ``);
      } else {
        personaTextColor = colorClass.trim();
      }
    });

    let dateBackgroundColor = ``;
    let dateTextColor = ``;

    if (this.personaDate) {
      this.personaDate.colorClasses.split(` `).forEach((colorClass, index) => {
        if (index === 0) {
          dateBackgroundColor = colorClass.trim().replace(`bg-`, ``);
        } else {
          dateTextColor = colorClass.trim();
        }
      });
    }

    let videoPadding = `py-24`;

    if (documentContext.windowHeight < 768) {
      videoPadding = `py-12`;
    }

    return (
      <>
        <SEO
          frontmatterTitle={frontmatter.title}
          frontmatterDescription={frontmatter.seoDescription}
          frontmatterKeywords={frontmatter.seoKeywords}
          frontmatterFacebookShareImage={`${siteUrl}/images/${seoTitle}-facebook.jpg`}
          frontmatterTwitterShareImage={`${siteUrl}/images/${seoTitle}-twitter.jpg`}
          frontmatterUrl={shareUrl}
        />

        {/* // backgrounds // */}

        <div
          className={`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 bg-${personaBackgroundColor}`}
        ></div>
        <div
          className="w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-10 bg-black"
          style={{ opacity: neutralBackgroundOpacity }}
        ></div>
        <div
          className={`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-20 bg-${dateBackgroundColor}`}
          style={{ opacity: dateBackgroundOpacity }}
        ></div>

        {/* // */}

        <Layout
          className="persona-page relative z-30"
          footerClass="white"
          headerClass="white"
        >
          <section className="grid">
            {device === `desktop` && (
              <article
                className={`persona-page__banner animation-appear w-1/2 sm:grid-end-24 h-screen left-0 ${videoPadding} flex items-center justify-center ${
                  this.state.dateSticky ? `absolute` : `fixed top-0`
                }`}
                style={{
                  animationDelay: `150ms`
                }}
              >
                <div className="persona-page__banner__inner w-full h-full relative overflow-hidden">
                  <video
                    ref={this.bannerVideoRef}
                    className="absolute transform-center"
                    src={frontmatter.hiDefVideo}
                    poster={frontmatter.videoPoster.childImageSharp.fluid.src}
                    autoPlay
                    loop
                    muted
                    playsInline
                  ></video>
                </div>

                <RotatingButtonLink
                  className="persona-page__banner__button infinispin absolute z-50"
                  filled
                  text="Share + Download"
                />
              </article>
            )}

            <article
              className={`persona-page__intro animation-appear grid-end-12 sm:grid-end-24 grid-start-13 sm:grid-start-1 pt-24 px-12 relative text-${personaTextColor}`}
              style={{
                animationDelay: `300ms`
              }}
            >
              <h4 className="b2">
                So you&apos;re a
                {frontmatter.title === `Everyday Epicurean` && `n`}
              </h4>

              <h1
                className={`${device === `desktop` ? `f3` : `f2`}`}
                dangerouslySetInnerHTML={{
                  __html: frontmatter.title.replace(` `, `<br/>`)
                }}
              ></h1>

              {device !== `desktop` && (
                <div className="w-full relative mt-8">
                  <video
                    ref={this.bannerVideoRef}
                    className="w-full relative"
                    src={frontmatter.sdDefVideo}
                    poster={frontmatter.videoPoster.childImageSharp.fluid.src}
                    autoPlay
                    loop
                    muted
                    playsInline
                  ></video>

                  <RotatingButtonLink
                    className="persona-page__intro__button infinispin absolute"
                    filled
                    text="Share + Download"
                  />
                </div>
              )}

              <h2
                className={`${
                  device === `desktop` ? `my-24` : `mt-16 mb-12`
                } f1`}
              >
                {frontmatter.tagline}
              </h2>

              <p
                className="persona-page__description b1"
                dangerouslySetInnerHTML={{
                  __html: frontmatter.description
                }}
              ></p>
            </article>

            <article
              className={`persona-page__facts animation-appear grid-end-12 sm:grid-end-24 grid-start-13 sm:grid-start-1 ${
                device === `desktop` ? `pt-24 px-12` : `pt-8`
              } relative text-${personaTextColor}`}
              style={{
                animationDelay: `450ms`
              }}
            >
              <h3
                className={`${
                  device === `desktop` ? `mb-16` : `mb-6 text-center`
                } f3`}
              >
                You know you’re a
                {frontmatter.title === `Everyday Epicurean` ? `n ` : ` `}
                {frontmatter.title} if:
              </h3>

              <ul
                className={`persona-page__list relative ${
                  device === `desktop` ? `mb-48` : `mb-8`
                }`}
              >
                {frontmatter.facts.map((fact, index) => {
                  const factIndex = index;

                  return (
                    <li key={`fact-${factIndex}`}>
                      <p className="relative flex my-4 f4">
                        <span className="mr-4">❋</span>
                        <span>{fact.fact}</span>
                      </p>
                    </li>
                  );
                })}
              </ul>
            </article>

            <article
              ref={this.neutralBackgroundRef}
              className={`persona-page__stats grid-end-12 sm:grid-end-24 grid-start-13 sm:grid-start-1 ${
                device === `desktop` ? `py-64 px-12` : `py-48 px-8`
              } relative flex items-center text-center text-white`}
            >
              <h3 className="f2 relative">
                {percentage < 10 ? `Only` : ``}
                {` `}
                {percentage}% of people are{` `}
                {frontmatter.title}s!
              </h3>
            </article>
          </section>

          <section className="grid relative z-50">
            {this.personaDate && device === `desktop` && (
              <div
                ref={this.dateBackgroundRef}
                className={`persona-page__date grid-end-12 grid-start-13 h-screen sticky top-0 right-0 ${videoPadding} flex items-center justify-center text-${dateTextColor}`}
              >
                <div className="w-full h-full relative overflow-hidden">
                  <video
                    ref={this.dateVideoRef}
                    className="persona-page__video absolute"
                    src={this.personaDate.hiDefVideo}
                    autoPlay
                    loop
                    muted
                    playsInline
                  ></video>
                </div>
              </div>
            )}

            {device === `desktop` && (
              <div
                ref={this.dateStickyRef}
                className="persona-page__hearts grid-end-24 h-screen relative"
              >
                <div className="persona-page__heart persona-page__heart--0 absolute z-20 flex items-center justify-center">
                  <span aria-label="Heart" role="img">
                    ❤️
                  </span>
                </div>
                <div className="persona-page__heart persona-page__heart--1 absolute z-20 flex items-center justify-center">
                  <span aria-label="Heart" role="img">
                    ❤️
                  </span>
                </div>
                <div className="persona-page__heart persona-page__heart--2 absolute z-20 flex items-center justify-center">
                  <span aria-label="Heart" role="img">
                    ❤️
                  </span>
                </div>
                <div className="persona-page__heart persona-page__heart--3 absolute z-20 flex items-center justify-center">
                  <span aria-label="Heart" role="img">
                    ❤️
                  </span>
                </div>
                <div className="persona-page__heart persona-page__heart--4 absolute z-20 flex items-center justify-center">
                  <span aria-label="Heart" role="img">
                    ❤️
                  </span>
                </div>
                <div className="persona-page__heart persona-page__heart--5 absolute z-20 flex items-center justify-center">
                  <span aria-label="Heart" role="img">
                    ❤️
                  </span>
                </div>
              </div>
            )}

            <article
              className={`persona-page__date__content min-h-screen grid-end-12 sm:grid-end-24 grid-start-1 ${
                device === `desktop` ? `py-24` : `pt-24 pb-12`
              } relative text-${dateTextColor}`}
            >
              <h3
                className={`${device === `desktop` ? `` : `text-center mb-4`}`}
              >
                Your perfect dinner date is
              </h3>

              <h2
                className={`${
                  device === `desktop` ? `mb-32 f3` : `text-center mb-12 f2`
                }`}
              >
                {frontmatter.dinnerDate}
              </h2>

              {this.personaDate && device !== `desktop` && (
                <div
                  ref={this.dateBackgroundRef}
                  className="w-full relative mb-12"
                >
                  <video
                    ref={this.dateVideoRef}
                    className="w-full relative"
                    src={this.personaDate.sdDefVideo}
                    autoPlay
                    loop
                    muted
                    playsInline
                  ></video>
                </div>
              )}

              <p
                className={`${
                  device === `desktop` ? `mb-8` : `mb-4 text-center`
                } b1`}
              >
                {frontmatter.dinnerDateText}
              </p>

              <p
                className={`${
                  device === `desktop` ? `mb-8` : `mb-4 text-center`
                } b1`}
              >
                Think you know a
                {frontmatter.dinnerDate === `Everyday Epicurean` && `n`}
                {` `}
                {frontmatter.dinnerDate}? Share the quiz with them.
              </p>

              <ul
                className={`relative flex ${
                  device === `desktop`
                    ? `items-start`
                    : `items-center justify-center`
                } mt-12`}
              >
                <li
                  className={`persona-page__share-icon relative ${
                    device === `desktop` ? `mr-6` : `px-6`
                  }`}
                >
                  <TwitterShareButton
                    className="relative flex items-center justify-center flex-col cursor-pointer"
                    url={shareUrl}
                  >
                    <SVGTwitter color={dateTextColor} />

                    <h4 className="mt-1 whitespace-no-wrap caption">Twitter</h4>
                  </TwitterShareButton>
                </li>

                <li
                  className={`persona-page__share-icon relative ${
                    device === `desktop` ? `mr-6` : `px-6`
                  }`}
                >
                  <FacebookShareButton
                    className="relative flex items-center justify-center flex-col cursor-pointer"
                    url={shareUrl}
                  >
                    <SVGFacebook color={dateTextColor} />

                    <h4 className="mt-1 whitespace-no-wrap caption">
                      Facebook
                    </h4>
                  </FacebookShareButton>
                </li>

                <li
                  className={`persona-page__share-icon relative ${
                    device === `desktop` ? `mr-6` : `px-6`
                  }`}
                >
                  <CopyToClipboard
                    text={shareUrl}
                    onCopy={() => {
                      this.setState({
                        copied: true
                      });
                    }}
                  >
                    <div className="relative flex items-center justify-center flex-col cursor-pointer">
                      <SVGChain color={dateTextColor} />

                      <h4 className="mt-1 whitespace-no-wrap caption">
                        {(copied && `Copied!`) || `Copy link`}
                      </h4>
                    </div>
                  </CopyToClipboard>
                </li>
              </ul>

              {device !== `desktop` && (
                <div className="grid-end-24 relative flex items-center justify-center mt-12">
                  <RotatingButtonLink
                    className="infinispin relative mx-auto"
                    filled
                    text="Share + Download"
                  />
                </div>
              )}
            </article>
          </section>

          {device === `desktop` && (
            <section className="relative">
              <PersonaHoverGrid personas={personas} />
            </section>
          )}

          <section
            ref={this.formRef}
            className="persona-page__form min-h-screen w-full relative flex items-center justify-center py-24 bg-black"
          >
            <div className="grid">
              <article className="grid-end-10 md:grid-end-12 sm:grid-end-20 xs:grid-end-22 grid-start-9 md:grid-start-7 sm:grid-start-3 xs:grid-start-2 flex flex-col items-center justify-center">
                <SignUpForm className="persona" />
              </article>
            </div>
          </section>
        </Layout>
      </>
    );
  }
}

const PersonaPage = ({ data }) => {
  const appContext = useContext(AppContext);
  const awsContext = useContext(AWSContext);
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;
  const personas = [];

  data.allMarkdownRemark.edges.forEach(edge => {
    const persona = edge.node.frontmatter;

    persona.url = edge.node.fields.slug;

    personas.push(persona);
  });

  const siteUrl = data.site.siteMetadata.url;
  const shareUrl = `${data.site.siteMetadata.url}${data.markdownRemark.fields.slug}`;

  return (
    <PersonaPageComponent
      appContext={appContext}
      awsContext={awsContext}
      documentContext={documentContext}
      frontmatter={frontmatter}
      shareUrl={shareUrl}
      siteUrl={siteUrl}
      personas={personas}
    />
  );
};

export default PersonaPage;

export const personaPageQuery = graphql`
  query PersonaPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        title
        navColor
        tagline
        flavorText
        description
        facts {
          fact
        }
        dinnerDate
        dinnerDateText
        colorClasses
        hiDefVideo
        sdDefVideo
        videoPoster {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "persona-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tagline
            description
            dinnerDate
            dinnerDateText
            colorClasses
            monoTextColor
            hiDefVideo
            sdDefVideo
            videoPoster {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`;
