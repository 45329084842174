/* eslint-disable react/prop-types */

import React, { Component } from "react";
import CountrySelector from "~components/CountrySelector";
import RotatingButtonLink from "~components/RotatingButtonLink";
import {
  fancyError,
  fancyLog,
  fancyWarning,
  validateEmail
} from "~utils/helpers";

import cheeseburger from "~assets/images/about/cheeseburger.png";

class SignUpFormComponent extends Component {
  state = {
    complete: false,
    form: {},
    submitting: false
  };

  requiredInputs = [`email`, `name`];

  //

  handleButtonSelect = gender => {
    const { form } = this.state;

    form.gender = {
      valid: true,
      value: gender
    };

    this.setState({
      form
    });
  };

  handleFocus = e => {
    const inputName = e.target.getAttribute(`name`);

    if (!this.state.form[inputName]) {
      const { form } = this.state;

      form[inputName] = {
        value: ``,
        interacted: false,
        valid: true
      };

      this.setState({
        form
      });
    }
  };

  handleInputChange = e => {
    const { form } = this.state;
    const { value } = e.target;
    const key = e.target.getAttribute(`name`);

    form[key].value = value;
    form[key].interacted = value !== ``;

    switch (key) {
      case `email`:
        form.email.valid = validateEmail(value);

        break;

      case `name`:
      case `first_name`:
      case `last_name`:
        form[key].valid = value !== ``;

        break;

      default:
        break;
    }

    this.setState({ form });
  };

  handleSelectChange = e => {
    const { form } = this.state;

    form[e.target.name] = {
      valid: true,
      value: e.target.value
    };

    this.setState({
      form
    });
  };

  //

  postSubmit = () => {
    const { onSubmit } = this.props;

    this.setState({
      complete: true,
      submitting: false
    });

    if (onSubmit) {
      onSubmit();
    }
  };

  submit = () => {
    if (!this.validate()) {
      return false;
    }

    const { form } = this.state;

    this.setState({
      submitting: true
    });

    const mailchimpData = {
      diet: form.diet.value || ``,
      email: form.email.value,
      gender: form.gender.value,
      list_id: `04eab27f28`,
      location: form.location.value || ``,
      name: form.name.value
    };

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/mailchimp`, {
      body: JSON.stringify(mailchimpData),
      method: `POST`
    })
      .then(() => {
        fancyLog(`Mailchimp Complete`);
        this.postSubmit();
      })
      .catch(error => {
        fancyError(error);
      });
  };

  validate = () => {
    let valid = true;

    if (!this.requiredInputs.length) {
      return valid;
    }

    const { form } = this.state;

    this.requiredInputs.forEach(requiredInput => {
      if (!form[requiredInput]) {
        form[requiredInput] = {
          interacted: true,
          valid: false,
          value: ``
        };

        valid = false;
      }
    });

    if (!valid) {
      this.setState({
        form
      });

      fancyWarning(`Required form values missing`);
    } else {
      Object.keys(form).forEach(inputKey => {
        if (!valid) {
          return;
        }

        if (form[inputKey].valid === false) {
          fancyWarning(`Invalid form value: ${inputKey}`);

          valid = false;
        }
      });
    }

    return valid;
  };

  //

  render() {
    const { complete, form, submitting } = this.state;
    const { className } = this.props;

    return (
      <div
        className={`sign-up-form relative ${className || ``} ${
          complete || submitting ? `opacity-50 pointer-events-none` : ``
        }`}
      >
        <img
          className="sign-up-form__image relative mx-auto"
          src={cheeseburger}
          alt="Cheeseburger"
        />

        <h3 className="sign-up-form__heading mb-6 b1 text-center text-white">
          Are you interested in helping us imagine the future of food?
        </h3>

        <p className="sign-up-form__subheading mb-8 xs:mt-4 xs:mb-10 b3 text-center text-white">
          Now that we know your food type, would you be comfortable with us
          asking occasional questions about the future of food?
        </p>

        <form className={`relative ${className && className}`}>
          <input
            name="name"
            className="w-full relative block mb-3 px-4 xs:px-3 b3 text-black"
            placeholder="Enter your name"
            type="text"
            onBlur={this.handleBlur}
            onChange={this.handleInputChange}
            onFocus={this.handleFocus}
          />

          <input
            name="email"
            className="w-full relative block mb-3 px-4 xs:px-3 b3 text-black"
            placeholder="Email address"
            type="email"
            onBlur={this.handleBlur}
            onChange={this.handleInputChange}
            onFocus={this.handleFocus}
          />

          <CountrySelector
            className="w-full relative mb-3 px-4 xs:px-3 block cursor-pointer b3 bg-white text-black"
            onChange={this.handleSelectChange}
          />

          <select
            className="w-full relative mb-3 px-4 xs:px-3 block cursor-pointer b3 text-black"
            name="diet"
            defaultValue="Diet"
            onChange={this.handleSelectChange}
          >
            <option disabled>Diet</option>
            <option>Pescetarian</option>
            <option>Vegetarian</option>
            <option>Vegan</option>
            <option>Gluten-Free</option>
            <option>Other</option>
          </select>

          <div className="w-full relative flex items-center justify-between xs:flex-col">
            <button
              type="button"
              className={`sign-up-form__button ${
                form.gender && form.gender.value === `male` ? `selected` : ``
              } button button--white xs:w-full relative xs:mb-3 pt-4 pb-3 b3 text-white`}
              onClick={() => this.handleButtonSelect(`male`)}
            >
              Male
            </button>

            <button
              type="button"
              className={`sign-up-form__button ${
                form.gender && form.gender.value === `female` ? `selected` : ``
              } button button--white xs:w-full relative xs:mb-3 pt-4 pb-3 b3 text-white`}
              onClick={() => this.handleButtonSelect(`female`)}
            >
              Female
            </button>

            <button
              type="button"
              className={`sign-up-form__button ${
                form.gender && form.gender.value === `unspecified`
                  ? `selected`
                  : ``
              } button button--white xs:w-full relative xs:mb-3 pt-4 pb-3 b3 text-white`}
              onClick={() => this.handleButtonSelect(`unspecified`)}
            >
              Rather not say
            </button>
          </div>

          {/* <label
            htmlFor="newsletter"
            className="sign-up-form__newsletter relative flex items-center pt-8 xs:pt-4"
          >
            <input
              className="cursor-pointer"
              name="newsletter"
              type="checkbox"
            />

            <span className="sign-up-form__newsletter__caption ml-3 b3 block text-white">
              Sign up for exclusive offers and info on upcoming events
            </span>
          </label> */}

          <RotatingButtonLink
            className="sign-up-form__submit absolute whitespace-no-wrap"
            filled
            onClick={this.submit}
            text={complete ? `Thanks!` : `Sign up`}
          />
        </form>
      </div>
    );
  }
}

const SignUpForm = ({ className, onSubmit }) => {
  return <SignUpFormComponent className={className} onSubmit={onSubmit} />;
};

export default SignUpForm;
